import React from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, deleteUser, signOut } from "firebase/auth";
import "./delete.css";

const Delete = () => {
  const navigate = useNavigate();
  const auth = getAuth();

  const handleDelete = async () => {
    try {
      await deleteUser(auth.currentUser!);
      await signOut(auth);
      navigate("/");
    } catch (error) {
      console.error("Delete account error:");
    }
  };

  const handleCancel = () => {
    navigate("/");
  };

  return (
    <div className="delete-container">
      <div className="delete-wrapper">
        <div className="bin-svg"></div>
        <p className="confirmation-text">
          Êtes-vous sûr de vouloir supprimer votre compte ?
        </p>
        <p className="delete-msg">
          Attention : La suppression de votre compte entraînera la perte
          irréversible de toutes vos données associées.
        </p>
        <button className="delete-button" onClick={handleDelete}>
          Oui, Supprimer
        </button>
        <button className="cancel-button" onClick={handleCancel}>
          Annuler
        </button>
      </div>
    </div>
  );
};

export default Delete;
