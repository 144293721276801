import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import * as yup from "yup";
import ".//login.css";

const Login = () => {
  const navigate = useNavigate();
  const loginSchema = yup.object().shape({
    email: yup
      .string()
      .email("Adresse e-mail invalide. Veuillez vérifier votre saisie.")
      .required("Veuillez saisir une adresse e-mail."),
    password: yup.string().required("Veuillez saisir un mot de passe."),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const handleInputChange = () => {
    setErrorMessage(null);
  };

  const submitForm = async (data: { email: string; password: string }) => {
    const { email, password } = data;
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/delete");
    } catch (error) {
      setErrorMessage("Échec de la connexion, vérifiez vos informations.");
    }
  };

  return (
    <div className="login-container">
      <div className="login-wrapper">
        <div className="logo-img"></div>
        <h1 className="login-text">Se Connecter</h1>

        <form onSubmit={handleSubmit(submitForm)}>
          <input
            className="input-slot"
            type="text"
            placeholder="VotreEmail@mail.com"
            {...register("email")}
            onChange={handleInputChange}
          />
          <p className="error-message1">{errors.email?.message}</p>

          <input
            className="input-slot"
            id="password"
            type="password"
            placeholder="Mot de passe"
            {...register("password")}
            onChange={handleInputChange}
          />
          <p className="error-message2">{errors.password?.message}</p>

          {errorMessage && <p className="error-message">{errorMessage}</p>}

          <input className="login-button" type="submit" value="Connexion" />
        </form>
      </div>
    </div>
  );
};

export default Login;
