import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAe3tA9A-6w-xxxqgrPDnTQkbo7vLMVN8Q",
  authDomain: "lookatoo-287d8.firebaseapp.com",
  projectId: "lookatoo-287d8",
  storageBucket: "lookatoo-287d8.appspot.com",
  messagingSenderId: "762763792367",
  appId: "1:762763792367:web:9ef7f07d6e6563cb53301a",
  measurementId: "G-TTKWVYGENN"
};

// Initialize Firebase
initializeApp(firebaseConfig);
export const auth = getAuth();
